import styled from 'styled-components';

const BannerSectionStyle = styled.div`
	--customContainerPaddingLeftRight: var(--sp15x);
	--bannerSectionPadding: var(--sp8x) var(--sp9x) var(--sp5x) var(--sp9x);
	--infoMaxWidth: var(--sp64x);
	--bannerDescriptionMargin: var(--sp2x) 0 var(--sp4x) 0;

	.banner-container {
		padding-left: var(--customContainerPaddingLeftRight) !important;
		padding-right: var(--customContainerPaddingLeftRight) !important;
		position: relative;
		overflow: hidden;

		&.anim {
			&:before,
			&:after {
				transform: scale(100);
			}

			.content-wrap {
				.animation-wrap {
					transform: scale(1);
				}
			}
		}

		&:before {
			content: '';
			clip-path: polygon(0 0, 98% 0, 98% 2%, 100% 2%, 100% 100%, 0 100%);
			width: 50%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: var(--lightWhite);
			transform-origin: 100% 0%;
			z-index: 10;
			transition: 1s ease-out;
		}

		&:after {
			content: '';
			clip-path: polygon(0 2%, 2% 2%, 2% 0, 100% 0, 100% 100%, 0 100%);
			width: 50%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			background-color: var(--lightWhite);
			transform-origin: 0% 0%;
			z-index: 10;
			transition: 1s ease-out;
		}

		.content-wrap {
			background-color: rgba(176, 227, 217, 0.1);
			padding: var(--bannerSectionPadding);

			.animation-wrap {
				display: flex;
				justify-content: space-between;
				align-items: center;
				transform: scale(1.1);
				transition: 1.2s ease;

				.info {
					display: flex;
					flex-direction: column;
					max-width: var(--infoMaxWidth);
					width: 100%;

					.banner-description {
						margin: var(--bannerDescriptionMargin);
					}
				}

				.animation-svg {
					width: 36.5%;

					.imageCont {
						padding-top: 100%;

						img {
							object-fit: contain;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--customContainerPaddingLeftRight: var(--sp6x);
		--bannerSectionPadding: var(--sp5x) var(--sp4x);
		--infoMaxWidth: var(--sp56x);
		--bannerDescriptionMargin: var(--sp2x) 0 var(--sp4x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--customContainerPaddingLeftRight: var(--sp5x);
		--bannerSectionPadding: var(--sp5x) var(--sp4x);
		--infoMaxWidth: var(--sp56x);
		--bannerDescriptionMargin: var(--sp2x) 0 var(--sp4x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--customContainerPaddingLeftRight: var(--sp4x);
		--bannerSectionPadding: var(--sp5x) var(--sp4x) var(--sp3x) var(--sp4x);
		--infoMaxWidth: var(--sp49x);
		--bannerDescriptionMargin: var(--sp2x) 0 var(--sp3x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--customContainerPaddingLeftRight: var(--sp3x);
		--bannerSectionPadding: var(--sp6x) var(--sp3x) var(--sp4x) var(--sp3x);
		--infoMaxWidth: var(--sp49x);
		--bannerDescriptionMargin: var(--sp2x) 0 var(--sp3x) 0;

		.banner-container {
			.content-wrap {
				.animation-wrap {
					align-items: flex-start;
					flex-direction: column;

					.animation-svg {
						width: 57%;
						margin-left: auto;
						margin-top: calc(var(--sp4x) * -1);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--customContainerPaddingLeftRight: var(--sp2x);
		--bannerSectionPadding: var(--sp3x);
		--infoMaxWidth: 100%;
		--bannerDescriptionMargin: var(--sp2x) 0 var(--sp3x) 0;

		.banner-container {
			.content-wrap {
				.animation-wrap {
					align-items: flex-start;
					flex-direction: column-reverse;

					.animation-svg {
						width: 100%;
						margin-bottom: var(--sp1x);
					}
				}
			}
		}
	}
`;

export default BannerSectionStyle;
