import { useRef, useEffect, useCallback, useMemo, useState } from 'react';

//* HOC's
import { withUIContext } from '@/context';

//* Helpers
import { config, useActiveSection } from '@/helpers';

//* Components
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';
import ShopItem from '@/components/common/ShopItem';
import Container from '@/components/common/Container';

//* Style
import ProductsSectionStyle from './style';

const ProductsSection = ({ specialOffers = [], winWidth }) => {
	//! State
	const [activeContIndex, setActiveContIndex] = useState();

	//! Refs
	const sectionRef = useRef();
	const productsContainerRef = useRef([]);

	//! Active section
	const activeSection = useActiveSection(sectionRef, 100, true);

	const handleScrollProducts = useCallback(() => {
		for (let i = 0; i < productsContainerRef.current.length; i++) {
			const element = productsContainerRef.current[i];

			if (element.getBoundingClientRect().top <= window.innerHeight * 0.8) {
				setActiveContIndex(i);
			}
		}
	}, []);

	useEffect(() => {
		if (activeSection) {
			window.addEventListener('scroll', handleScrollProducts);
		}

		return () => {
			window.removeEventListener('scroll', handleScrollProducts);
		};
	}, [activeSection]);

	//! Checks
	const isLargeDesktop = useMemo(() => {
		return winWidth < 2560 ? 3 : 4;
	}, [winWidth]);

	//! Shop items
	const storeShopItems = useMemo(() => {
		const chunks = [];

		for (let i = 0; i < specialOffers.length; i += isLargeDesktop) {
			chunks.push(specialOffers.slice(i, i + isLargeDesktop));
		}

		return chunks.map((row, index) => {
			return (
				<div
					key={index}
					ref={(ref) => (productsContainerRef.current[index] = ref)}
					className={`products-container ${activeContIndex >= index ? 'active' : ''}`}>
					{row.map((item, index) => {
						return (
							<div
								key={index}
								className={`product-col ${`product-col-${index}`}`}>
								<ShopItem {...item} />
							</div>
						);
					})}
				</div>
			);
		});
	}, [specialOffers, activeContIndex, isLargeDesktop]);

	return (
		<ProductsSectionStyle ref={sectionRef}>
			<Container
				section
				className={'products-listing'}>
				<div className='products-left'>
					<div className='product-left-title'>
						<Text
							className={'h1 font-futura-medium'}
							text={'ourSpecial'}
						/>
						<Text
							className={'h1 font-futura-medium'}
							text={'offers'}
						/>
					</div>

					<Button
						text={'viewAll'}
						btnType={'float-underline'}
						url={config.routes.shop.path}
					/>
				</div>

				<div className='products-right'>
					<div className={'products-main-container'}>{storeShopItems}</div>
				</div>
			</Container>
		</ProductsSectionStyle>
	);
};

export default withUIContext(ProductsSection, ['winWidth']);
