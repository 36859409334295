import { useMemo } from 'react'

//* Style
import TestimonialSectionStyle from './style'

//* Components
import Container from '@/components/common/Container'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'


const slides = [
    {
        author: 'Milton Austin',
        line_1: '<strong>Fantastic service,</strong><p> very reasonable prices.</p>',
        line_2: "<p>I will definitely use </p><strong>Zoya's Pantry</strong><p> in the future!</p>",
    },
    {
        author: 'Milton Austin',
        line_1: '<strong>Fantastic service,</strong><p> very reasonable prices.</p>',
        line_2: "<p>I will definitely use </p><strong>Zoya's Pantry</strong><p> in the future!</p>",
    },
    {
        author: 'Milton Austin',
        line_1: '<strong>Fantastic service,</strong><p> very reasonable prices.</p>',
        line_2: "<p>I will definitely use </p><strong>Zoya's Pantry</strong><p> in the future!</p>",
    },
]

const TestimonialSection = () => {
    //! Slides
    const storeSlides = useMemo(() => {
        return slides.map((slide, index) => {
            return (
                <SwiperSlide key={index}>
                    <div
                        className='slide-content line-1'
                        dangerouslySetInnerHTML={{ __html: slide.line_1 }}
                    />
                    <div
                        className='slide-content line-2'
                        dangerouslySetInnerHTML={{ __html: slide.line_2 }}
                    />
                </SwiperSlide>
            )
        })
    }, [slides])

    return (
        <TestimonialSectionStyle>
            <Container section>
                <Swiper
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    autoplay={true}
                    modules={[Pagination, Autoplay]}
                >
                    {storeSlides}
                </Swiper>
            </Container>
        </TestimonialSectionStyle>
    )
}

export default TestimonialSection
