import { useMemo } from 'react';

//* Style
import OurBrandsSectionStyle from './style';

//* Components
import { SwiperSlide, Swiper } from 'swiper/react';
import { FreeMode } from 'swiper';
import Container from '@/components/common/Container';
import Text from '@/components/common/Text';
import Image from '@/components/common/Image';
import CustomLink from '@/components/common/CustomLink';

const OurBrandsSection = ({ brands }) => {
	//! Brands
	const storeBrands = useMemo(() => {
		return brands.map((item, index) => (
			<SwiperSlide key={index}>
				<CustomLink url={item.url}>
					<div
						className='image-wrap'
						key={index}>
						<Image
							src={item.logo.src}
							alt={item.logo.alt}
						/>
					</div>
				</CustomLink>
			</SwiperSlide>
		));
	}, [brands]);

	return (
		<OurBrandsSectionStyle>
			<Container
				className={'brands-container'}
				section>
				<Text
					className={'h1 font-futura-medium section-title'}
					text={'ourBrands'}
				/>
				<Swiper
					freeMode={true}
					modules={[FreeMode]}
					slidesPerView={'auto'}>
					{storeBrands}
				</Swiper>
			</Container>
		</OurBrandsSectionStyle>
	);
};

export default OurBrandsSection;
