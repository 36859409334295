import { useRef, useMemo } from 'react';

//* Style
import BannerSectionStyle from './style';

//* HOC's
import { withLanguageContext } from '@/context';

//* Lottie's
import Banner from '@/lottieFiles/banner.json';

//* Helpers
import { config, useActiveSection } from '@/helpers';

//* Components
import Text from '@/components/common/Text';
import Container from '@/components/common/Container';
import Button from '@/components/common/Button';
import LottieAnimation from '@/components/common/LottieAnimation';

const BannerSection = ({ translate }) => {
	//! Refs
	const sectionRef = useRef();

	//! Active section
	const activeSection = useActiveSection(sectionRef);

	const storeLottie = useMemo(() => {
		return (
			<LottieAnimation
				animData={Banner}
				delayPlay={{ timeout: 1300 }}
				autoplay={false}
				isPLay={false}
				loop={false}
			/>
		);
	}, []);

	return (
		<BannerSectionStyle ref={sectionRef}>
			<Container
				section
				className={`banner-container ${activeSection ? 'anim' : ''}`}>
				<div className={'content-wrap'}>
					<div className='animation-wrap'>
						<div className='info'>
							<Text
								tag={'h1'}
								className={'h1-L font-futura-light'}>
								{translate('yourOneStop')} <span className='font-futura-semibold'>{translate('healthy&Organic')}</span> {translate('onlineStore')}
							</Text>
							<Text
								className={'h5 font-futura-regular banner-description'}
								text={'From local products to fresh produce, our expert team members will help you with the groceries you know and love.'}
							/>
							<Button
								size={'medium'}
								text={'exploreTheRange'}
								url={config.routes.pantry.path}
							/>
						</div>
						<div className='animation-svg'>{storeLottie}</div>
					</div>
				</div>
			</Container>
		</BannerSectionStyle>
	);
};

export default withLanguageContext(BannerSection, ['translate']);
