import styled from 'styled-components';

const ShopByCategoryStyleSection = styled.div`
	--lowerTitleMarginBottom: var(--sp4x);
	--slideMarginRight: var(--sp6x);
	--slideItemName: var(--sp4x);

	--swiperSlideWidth: 19.5vw;
	--swiperSlideImagePaddingTop: 138.4%;

	.swiper {
		padding-left: var(--contPaddingLR);
	}

	.swiper-wrap {
		.swiper-wrapper {
			.swiper-slide {
				position: relative;
				width: var(--swiperSlideWidth) !important;

				.slide-item-wrap {
					.imageCont {
						padding-top: var(--swiperSlideImagePaddingTop);
					}

					.slide-item-name {
						margin-top: var(--slideItemName);
					}
				}

				&:not(:last-child) {
					margin-right: var(--slideMarginRight);
				}

				&:last-child {
					width: calc(var(--swiperSlideWidth) + var(--contPaddingLR)) !important;
					padding-right: var(--contPaddingLR);
				}
			}
		}
	}

	.slide-item-wrap {
		&.mobile {
			padding: 0 var(--contPaddingLR);
			margin-bottom: var(--sp3x);
		}
	}

	.lower-title {
		margin-bottom: var(--lowerTitleMarginBottom);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--lowerTitleMarginBottom: var(--sp4x);
		--slideMarginRight: var(--sp4x);
		--slideItemName: var(--sp4x);

		--swiperSlideWidth: 21.1vw;
		--swiperSlideImagePaddingTop: 120%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--lowerTitleMarginBottom: var(--sp3x);
		--slideMarginRight: var(--sp4x);
		--slideItemName: var(--sp4x);

		--swiperSlideWidth: 23.8vw;
		--swiperSlideImagePaddingTop: 138%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--lowerTitleMarginBottom: var(--sp3x);
		--slideMarginRight: var(--sp3x);
		--slideItemName: var(--sp3x);

		--swiperSlideWidth: 24.6vw;
		--swiperSlideImagePaddingTop: 138%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--lowerTitleMarginBottom: var(--sp3x);
		--slideMarginRight: var(--sp3x);
		--slideItemName: var(--sp3x);

		--swiperSlideWidth: 29.2vw;
		--swiperSlideImagePaddingTop: 138%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--lowerTitleMarginBottom: var(--sp2x);
		--slideMarginRight: var(--sp2x);
		--slideItemName: var(--sp2x);

		--swiperSlideWidth: 61.9vw;
		--swiperSlideImagePaddingTop: 138%;
	}
`;

export default ShopByCategoryStyleSection;
