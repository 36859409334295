import styled from 'styled-components';

const ProductsSectionStyle = styled.div`
	--productsLeftMaxWidth: calc((100% / 8) * 2 + var(--gutter));
	--productsLeftTitle: var(--sp4x);
	--productColWidth: calc(100% / 3);

	.products-listing {
		display: flex;
		flex-wrap: wrap;
	}

	.products-left {
		width: var(--productsLeftMaxWidth);
		margin-right: var(--gutter);
	}

	.products-right {
		flex: 1;
	}

	.product-left-title {
		margin-bottom: var(--productsLeftTitle);
	}

	.product-col {
		width: var(--productColWidth);
		margin-top: var(--gutter);
		padding-left: var(--colPadding);
		padding-right: var(--colPadding);
		transform: translateY(100px);

		&-0 {
			transition: transform 0.2s ease-out;
		}

		&-1 {
			transition: transform 0.4s ease-out;
		}

		&-2 {
			transition: transform 0.6s ease-out;
		}
	}

	.products-container {
		display: flex;
		margin-left: calc(var(--colPadding) * -1);
		margin-right: calc(var(--colPadding) * -1);

		&:first-child {
			margin-top: calc(var(--gutter) * -1);
		}

		&.active {
			.product-col {
				transform: translateY(0);
			}
		}
	}

	@media only screen and (min-width: 2560px) {
		--productColWidth: calc(100% / 4);

		.product-col {
			&-3 {
				transition: transform 0.8s ease-out;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--productsLeftMaxWidth: calc((100% / 8) * 2);
		--productsLeftTitle: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--productsLeftMaxWidth: calc((100% / 8) * 2);
		--productsLeftTitle: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--productsLeftMaxWidth: calc((100% / 8) * 2);
		--productsLeftTitle: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--productsLeftMaxWidth: 100%;
		--productsLeftTitle: var(--sp3x);

		.products-left {
			margin-right: 0;
			margin-bottom: var(--sp4x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--productsLeftMaxWidth: 100%;
		--productsLeftTitle: var(--sp3x);
		--productColWidth: 100%;

		.products-left {
			margin-right: 0;
			margin-bottom: var(--sp3x);
		}

		.products-container {
			flex-direction: column;
		}
	}
`;

export default ProductsSectionStyle;
