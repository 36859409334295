import styled from 'styled-components'

const TestimonialSectionStyle = styled.div`
	--slideLineMarginLeft: var(--sp10x);
    --paginationBulletSizes: var(--sp3-5x);
    --paginationBulletMargin: 0 var(--sp2x);

	.swiper-slide {
		display: flex;
		flex-direction: column;
		align-items: center;

		.slide-content {
			p {
				font-size: var(--h1);
				font-weight: 300;
				font-family: var(--futura);
				display: inline;
			}

			strong {
				font-size: var(--h1);
				font-weight: 500;
				font-family: var(--futura);
				display: inline;
			}

			&.line-1 {
				margin-left: calc(var(--slideLineMarginLeft) * -1);
			}

			&.line-2 {
				margin-left: var(--slideLineMarginLeft);
			}
		}
	}

	.swiper-pagination {
		display: flex;
		justify-content: center;
		margin-top: var(--sp5x);

		.swiper-pagination-bullet {
			display: block;
			background-image: url('/images/pagination-avocado.svg');
			background-repeat: no-repeat;
            background-size: contain;
			width: var(--paginationBulletSizes);
			height: var(--paginationBulletSizes);
            margin: var(--paginationBulletMargin);
            opacity: 0.3;
            cursor: pointer;

            &.swiper-pagination-bullet-active {
                opacity: 1;
            }
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--slideLineMarginLeft: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--slideLineMarginLeft: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--slideLineMarginLeft: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--slideLineMarginLeft: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--slideLineMarginLeft: 0;

		.slide-content {
			text-align: center;
		}
	}
`

export default TestimonialSectionStyle
