'use client';

import { useEffect, useState } from 'react';

//* HOC's
import { withDataContext } from '@/context';

//* Components
import Page from '@/components/common/Page';
import ScrollText from '@/components/sections/ScrollText';
import FormSection from '@/components/sections/FormSection';
import BannerSection from '@/components/pages/home/BannerSection';
import WhyZoyasSection from '@/components/sections/WhyZoyasSection';
import ProductsSection from '@/components/sections/ProductsSection';
import OurBrandsSection from '@/components/pages/home/OurBrandsSections';
import ShopByCategory from '@/components/pages/home/ShopByCategorySection';
import TestimonialSection from '@/components/pages/home/TestimonialSection';

//* Style
import HomeStyle from '@/styles/pages/HomeStyle';

const HomeTemplate = ({ specialOffers, brands }) => {
    const [stateRefreshOnLoad, setStateRefreshOnLoad] = useState(false);

    useEffect(() => {
        setStateRefreshOnLoad(true);
    }, []);

    return (
        <Page className={'home'}>
            <HomeStyle>
                <BannerSection />

                {stateRefreshOnLoad && (
                    <>
                        <ShopByCategory />

                        <ScrollText />

                        <ProductsSection specialOffers={specialOffers} />

                        <TestimonialSection />

                        <OurBrandsSection brands={brands} />

                        <WhyZoyasSection />

                        <FormSection />
                    </>
                )}
            </HomeStyle>
        </Page>
    );
};

export default withDataContext(HomeTemplate, ['pages']);
