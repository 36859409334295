import styled from 'styled-components';

const OurBrandsSectionStyle = styled.div`
	--swiperSlideWidth: 14.3vw;
	--swiperSlideMarginRight: var(--sp2x);
	--swiperSlideImagePaddingTop: 73.2%;
	--sectionTitleMarginBottom: var(--sp4x);

	.swiper {
		padding-left: 20.3%;
	}

	.section-title {
		margin-bottom: var(--sectionTitleMarginBottom);
	}

	.brands-container {
		padding-right: unset;

		.swiper-wrapper {
			.swiper-slide {
				width: var(--swiperSlideWidth);

				.imageCont {
					padding-top: var(--swiperSlideImagePaddingTop);

					img {
						object-fit: contain;
					}
				}

				&:not(:last-child) {
					margin-right: var(--swiperSlideMarginRight);
				}

				&:last-child {
					width: calc(var(--swiperSlideWidth) + var(--contPaddingLR));
					padding-right: var(--contPaddingLR);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--swiperSlideWidth: 15.7vw;
		--swiperSlideMarginRight: var(--sp2x);
		--sectionTitleMarginBottom: var(--sp3x);

		.swiper {
			padding-left: 21.6%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--swiperSlideWidth: 17.7vw;
		--swiperSlideMarginRight: var(--sp2x);
		--sectionTitleMarginBottom: var(--sp2x);

		.swiper {
			padding-left: 21.2%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--swiperSlideWidth: 17.4vw;
		--swiperSlideMarginRight: var(--sp1x);
		--sectionTitleMarginBottom: var(--sp2x);

		.swiper {
			padding-left: 20.9%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--swiperSlideWidth: 22.3vw;
		--swiperSlideMarginRight: var(--sp1x);
		--sectionTitleMarginBottom: var(--sp2x);

		.swiper {
			padding-left: unset;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--swiperSlideWidth: 45.4vw;
		--swiperSlideMarginRight: var(--sp1x);
		--sectionTitleMarginBottom: var(--sp2x);

		.swiper {
			padding-left: unset;
		}
	}
`;

export default OurBrandsSectionStyle;
