import { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';

//* HOC's
import { withUIContext, withDataContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '@/components/common/Text';
import Image from '@/components/common/Image';
import Button from '@/components/common/Button';
import Container from '@/components/common/Container';
import CustomLink from '@/components/common/CustomLink';

//* Style
import ShopByCategorySectionsStyle from './style';

const ShopByCategorySections = ({ globalData, winWidth }) => {
    //! Store shop items
    const storeShopItems = useMemo(() => {
        return globalData.categories?.length > 0
            ? globalData.categories.map((item, index) => {
                return (
                    <SwiperSlide key={index}>
                        <CustomLink url={item.url}>
                            <div className='slide-item-wrap'>
                                <Image
                                    src={item.image.src}
                                    alt={item.image.alt}
                                />
                                <Text
                                    className={'h2 font-futura-medium slide-item-name'}
                                    text={item.name}
                                />
                            </div>
                        </CustomLink>
                    </SwiperSlide>
                );
            })
            : null;
    }, [globalData]);

    return (
        <ShopByCategorySectionsStyle>
            <Container
                full
                section>
                <div className='swiper-wrap'>
                    {!(winWidth >= 768) ? (
                        <div className='slide-item-wrap mobile'>
                            <Text
                                className={'h1 font-futura-medium'}
                                text={'shop'}
                            />

                            <Text
                                className={'h1 font-futura-medium lower-title'}
                                text={'byCategory'}
                            />

                            <Button
                                text={'viewAllCategories'}
                                btnType={'float-underline'}
                                url={config.routes.shop.path}
                            />
                        </div>
                    ) : null}

                    <Swiper
                        freeMode={true}
                        modules={[FreeMode]}
                        slidesPerView={'auto'}>
                        {winWidth >= 768 ? (
                            <SwiperSlide>
                                <div className='slide-item-wrap'>
                                    <Text
                                        className={'h1 font-futura-medium'}
                                        text={'shop'}
                                    />

                                    <Text
                                        className={'h1 font-futura-medium lower-title'}
                                        text={'byCategory'}
                                    />

                                    <Button
                                        text={'viewAllCategories'}
                                        btnType={'float-underline'}
                                        url={config.routes.shop.path}
                                    />
                                </div>
                            </SwiperSlide>
                        ) : null}

                        {storeShopItems}
                    </Swiper>
                </div>
            </Container>
        </ShopByCategorySectionsStyle>
    );
};

export default withUIContext(withDataContext(ShopByCategorySections, ['globalData']), ['winWidth']);
